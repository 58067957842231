import React from "react"
import WhatLayout from "../../components/WhatLayout";
import bild01 from "../../images/what/gayart18/butterflies.png";
import bild02 from "../../images/what/gayart18/paganspirit.png";
import bild03 from "../../images/what/gayart18/rotwein-oracle.png";
import bild04 from "../../images/what/gayart18/cole-claire.png";
import bild05 from "../../images/what/gayart18/higher-love.png";
import bild06 from "../../images/what/gayart18/higher-love-part2.png";
import bild07 from "../../images/what/gayart18/prayer.png";

const Page = () => {

  return (
    <WhatLayout
      minAge={16}
      header={"Gayart 18+"}
      images={[
        bild01,
        bild02,
        bild03,
        bild04,
        bild05,
        bild06,
        bild07
      ]}
    />
  );

};

export default Page;
